import classNames from 'classnames'

import styles from './subtitle.module.scss'

export const Subtitle = ({ className = '', children }) => {
    return (
        <p data-cy='cy-subtitle' className={classNames(styles.subtitle, className)}>
            {children}
        </p>
    )
}
